import React from 'react';
import ArrowUp from '../../../assets/images/editor/arrow-up.svg';
import ArrowDown from '../../../assets/images/editor/arrow-down.svg';
import Close from '../../../assets/images/editor/close.svg';
import MatchCase from '../../../assets/images/editor/match-case.svg';
import RegExp from '../../../assets/images/editor/regexp.svg';
import Word from '../../../assets/images/editor/word.svg';
import * as styles from './editor-icon.module.scss';


const ICONS = {
  ArrowUp,
  ArrowDown,
  Close,
  MatchCase,
  RegExp,
  Word,
};

export function EditorIcon( { name } ) {
  if ( ICONS[ name ] ) {
    return React.createElement( ICONS[ name ], { className: styles.editorIcon } );
  }

  return null;
}