module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/runner/work/ryusei-code-site/ryusei-code-site/plugins/gatsby-plugin-ryusei-light-remark","id":"55e54a65-8e83-5730-84b4-9c6da14d7196","name":"gatsby-plugin-ryusei-light-remark","version":"1.0.0","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/ryusei-code-site/ryusei-code-site/plugins/gatsby-plugin-ryusei-light-remark/index.js","options":{"languageName":true,"copy":{"html":"<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\">\n  <path d=\"m16 1a2 2 0 0 0-2 2v23a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2v-23a2 2 0 0 0-2-2h-21zm2 4h17v19h-17v-19z\"/>\n  <path d=\"m3 12a2 2 0 0 0-2 2v23a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2v-23a2 2 0 0 0-2-2h-21z\"/>\n</svg>","activeHtml":"<svg style=\"stroke-linecap: round\" viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\">\n <path d=\"m15 31 21-21m-32 10 11 11\" stroke-width=\"6\"/>\n</svg>"}}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"wrapperStyle":"margin: 1.5rem auto; border-radius: 0.5em; overflow: hidden;","linkImagesToOriginal":false}},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/ryusei-code-site/ryusei-code-site"},
    },{
      plugin: require('../plugins/gatsby-plugin-ryusei-light-remark/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"RyuseiCode","short_name":"RyuseiCode","description":"RyuseiCode is a lightweight, extensible and accessible code editor","start_url":"/","background_color":"#28353e","theme_color":"#00c2f2","display":"standalone","legacy":false,"icon":"src/assets/images/site/favicon-192x192.png","icons":[{"src":"src/assets/images/site/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/site/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c076c092862f0e0a0d002ea1baeb2e8d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-7WG94MG1RK","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    }]
